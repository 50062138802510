<template>
  <div>
    <!-- Start Header Area -->
    <!-- <Header>
      <img slot="logo" src="../../assets/images/logo/logo.png" />
    </Header> -->
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h2 class="heading-title">Portfolio</h2>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                    item.text
                  }}</router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Area Featured  -->
    <!-- <div class="rn-portfolio-area rn-section-gap bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="section-title text-center mb--20 mb_sm--0">
              <h2 class="heading-title">Featured</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                <br />
                but the majority have suffered alteration.
              </p>
            </div>
          </v-col>
        </v-row>
        <PortfolioTwo />
      </v-container>
    </div> -->
    <!-- Start Portfolio Area  -->

    <!-- Start Portfolio Area all projects -->
    <div class="rn-portfolio-area rn-section-gap bg_color--5">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="section-title text-center mb--20 mb_sm--0">
              <h2 class="heading-title">{{ $t("all_works") }}</h2>
              <!-- <p>
                {{ $t("projects.description") }}
              </p> -->
            </div>
          </v-col>
        </v-row>
        <!-- End .row -->
        <PortfolioFour :portfolioContent="portfolioContent" />
        <v-row>
          <v-col lg="12">
            <div class="rn-pagination text-center mt--60 mt_sm--30">
              <Pagination
                :pages="pageSize"
                :activePage="activePage"
                @changePage="changePage"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Start Portfolio Area  -->

    <!-- Start Portfolio Area  -->
    <!-- <div class="rn-portfolio-area rn-section-gap bg_color--1">
      <div class="portfolio-sacousel-inner pb--30">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="section-title text-center mb--20 mb_sm--0">
                <h2 class="heading-title">All Works</h2>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available,
                  <br />
                  but the majority have suffered alteration.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <Portfolio />
      </div>
    </div> -->
    <!-- Start Portfolio Area  -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../components/header/Header.vue";
import PortfolioTwo from "../../components/portfolio/PortfolioTwo.vue";
import PortfolioFour from "../../components/portfolio/PortfolioNine.vue";
import Portfolio from "../../components/portfolio/Portfolio.vue";
import Footer from "../../components/footer/FooterTwo.vue";
import Pagination from "../../components/pagination/Pagination.vue";
import { computed, onMounted, reactive, watch, ref } from "@vue/composition-api";
import api from "../../services/api";
import constants from "../../constants"
export default {
  components: {
    Header,
    PortfolioTwo,
    PortfolioFour,
    Portfolio,
    Footer,
    Pagination,
  },
  setup(props, { root }) {
    const store = root.$store;
    const language = computed(() => store.state.app.language);
    const portfolioContent = reactive([]);
    const pageSize = ref(1);
    const activePage = ref(1);
    const limit = 3

    function fetchData() {
      const offset = (activePage.value - 1) * limit
      const url = `/project/?active=true&limit=${limit}&offset=${offset}`;
      api
        .get(url)
        .then((response) => { 
          pageSize.value = Math.ceil(response.data.count / limit);
          response.data.results.map((x) => {
            if (x.image.includes(".gif")) {
              x.cropped_image = x.image;
            }
            return x;
          });
          portfolioContent.splice(0);
          portfolioContent.push(...response.data.results);
        })
        .catch((err) => {
          console.error("cannot get projects");
          console.log(err);
        });
    }

    function changePage(pageNumber) {
      // console.log("I receved", pageNumber);
      activePage.value = pageNumber
    }

    onMounted(() => {
      fetchData()
    })

    watch(
      activePage,
      () => {
        fetchData();
      },
    );

    return {
      portfolioContent,
      changePage,
      pageSize,
      activePage,
    };
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
          disabled: false,
        },
        {
          text: "Portfolio",
          to: "",
          disabled: true,
        },
      ],
    };
  },
};
</script>
