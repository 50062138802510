<template>
  <ul class="page-list">
    <li>
      <a @click="changePage(activePage - 1)"
        ><i class="fas fa-angle-left"></i
      ></a>
    </li>
    <!-- <li class="active"><a href="#">1</a></li>-->
    <li
      v-for="page in pages"
      :key="page"
      :class="page == activePage ? 'active' : ''"
    >
      <a @click="changePage(page)">{{ page }}</a>
    </li>
    <li>
      <a @click="changePage(activePage + 1)"
        ><i class="fas fa-angle-right"></i
      ></a>
    </li>
  </ul>
</template>

<script>
import { toRefs } from "@vue/composition-api";
export default {
  props: {
    pages: { type: Number },
    activePage: { type: Number, default: () => 1 },
  },
  // emits: ['changePage'],
  setup(props, { emit }) {
    function changePage(pageNumber) {
      // console.log("page number", pageNumber);
      // console.log("active page", props.activePage);
      if (
        pageNumber >= 1 &&
        pageNumber <= props.pages &&
        pageNumber !== props.activePage
      ) {
        emit("changePage", pageNumber);
      }
    }

    return {
      changePage,
    };
  },
};
</script>
