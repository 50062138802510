<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="slick-space-gutter--15">
          <VueSlickCarousel
            v-bind="settings"
            class="rn-slick-activation slick-dotted rn-slick-dot mt--10"
            v-if="portfolioContent.length > 0"
          >
            <div
              class="portfolio mt--30 mb--20"
              v-for="(item, i) in portfolioContent"
              :key="i"
            >
              <div class="thumbnail-inner">
                <v-img class="thumbnail" :src="item.cropped_image"></v-img>
                <v-img class="bg-blr-image" :src="item.cropped_image"></v-img>
              </div>
              <div class="content">
                <div class="inner">
                  <p>{{ item.name }}</p>
                  <h4>
                    <router-link to="portfolio-details">{{
                      item.translations[language].title
                    }}</router-link>
                  </h4>
                  <div class="portfolio-button">
                    <router-link class="rn-btn" to="/portfolio-details"
                      >Case Study</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- End Single Portfolio  -->
          </VueSlickCarousel>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import { reactive, watch, computed } from "@vue/composition-api";
import api from "../../services/api";

export default {
  components: {
    VueSlickCarousel,
  },
  setup(props, { root }) {
    const store = root.$store;
    const language = computed(() => store.state.app.language);
    const portfolioContent = reactive([]);

    function fetchData() {
      api
        .get(`/project?language=${language.value}&limit=6`)
        .then((response) => {
          response.data.results.map(x => {
            if(x.image.includes('.gif')){
              x.cropped_image = x.image
            }
            return x
          })
          portfolioContent.splice(0);
          portfolioContent.push(...response.data.results);
        })
        .catch((err) => {
          console.error("cannot get projects");
          console.log(err);
        });
    }

    watch(
      language,
      () => {
        fetchData();
      },
      { immediate: true }
    );

    return {
      portfolioContent,
      language
    };
  },
  data() {
    return {
      // for all works
      settings: {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        spaceBetween: 15,

        responsive: [
          {
            breakpoint: 890,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 770,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 490,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
      },
    };
  },
};
</script>
