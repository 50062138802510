var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',_vm._l((_vm.portfolioContent),function(item,i){return _c('v-col',{key:i,attrs:{"lg":"4","md":"4","sm":"6","cols":"12"}},[_c('div',{staticClass:"blog blog-style--1"},[_c('div',{staticClass:"thumbnail"},[_c('router-link',{attrs:{"to":{
            name: 'PortfolioDetails',
            params: { slug: item.slug },
          }}},[_c('img',{staticClass:"w-100",attrs:{"src":item.cropped_image,"alt":"Blog Images"}})])],1),_c('div',{staticClass:"content"},[_c('p',{staticClass:"blogtype"},[_vm._v(_vm._s(item.name))]),_c('h4',{staticClass:"heading-title"},[_c('router-link',{attrs:{"to":{
              name: 'PortfolioDetails',
              params: { slug: item.slug },
            }}},[_vm._v(_vm._s(item.translations[_vm.language].title)+" ")])],1),_c('div',{staticClass:"blog-btn"},[_c('router-link',{staticClass:"rn-btn white--text",attrs:{"to":{
              name: 'PortfolioDetails',
              params: { slug: item.slug },
            }}},[_vm._v("Read More ")])],1)])])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }