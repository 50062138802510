<template>
  <v-row>
    <!-- Start Blog Area  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="(item, i) in portfolioContent"
      :key="i"
    >
      <div class="blog blog-style--1">
        <div class="thumbnail">
          <router-link
            :to="{
              name: 'PortfolioDetails',
              params: { slug: item.slug },
            }"
          >
            <img class="w-100" :src="item.cropped_image" alt="Blog Images" />
          </router-link>
        </div>
        <div class="content">
          <p class="blogtype">{{ item.name }}</p>
          <h4 class="heading-title">
            <router-link
              :to="{
                name: 'PortfolioDetails',
                params: { slug: item.slug },
              }"
              >{{ item.translations[language].title }}
            </router-link>
          </h4>
          <div class="blog-btn">
            <router-link
              class="rn-btn white--text"
              :to="{
                name: 'PortfolioDetails',
                params: { slug: item.slug },
              }"
              >Read More
            </router-link>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Blog Area  -->
  </v-row>
</template>

<script>
import { computed } from "@vue/composition-api";
export default {
  props: {
    portfolioContent: {
      type: Array,
      // default: () => [
      //   {
      //     image: require("../../assets/images/portfolio/portfolio-8.jpg"),
      //     cropped_image: "",
      //     meta: "Development",
      //     translations: {
      //       en: {
      //          title: "Getting tickets to the big show",
      //       }
      //     }

      //   },
      // ],
    },
  },
  setup(props, { root }) {
    const store = root.$store;
    const language = computed(() => store.state.app.language);

    return {
      language,
    };
  },
};
</script>
